import React from 'react'
import { Container, Row } from 'react-bootstrap';
import faqHero from '../assets/img/faqHero.webp';
import Faq from "react-faq-component";
import './FAQ.css';


const data = {
  rows: [
      {
          title: "What is Weiw?",
          content: `Weiw is a platform which provides credentials on hourly basis to those film junkies that  
           want to watch new movies released or their favorite old rom com, but do not want to  
           purchase a complete subscription for one particular movie/show.`,
      },
      {
          title: "How to use Weiw?",
          content:
            `Step 1: Visit weiw.in. <br/>
            Step 2: Choose the respective OTT platform from the pricing section.<br/>
            Step 3 : Click on buy now or Go to bottom of page by fill details and finish payment.<br/>
            Step 4 : You will be getting credentials to your given number after the payment verification.`,
      },
      {
          title: "What are the benefits of Weiw?",
          content: `With Weiw, you can avoid the traditional way of purchasing plans on a monthly or annual basis.`,
      },
      {
        title: "How many devices can I watch on??",
        content: ` Only one account can be accessible with one purchase.`,
      },
      {
          title: "How can I upgrade my Plan?",
          content:`Whenever the plan is about to expire, the team weiw will send a remainder message, where  
          you can either reply to the message else can reach out the team through any social media 
          handles.
`,
      },
      {
          title: "Is it safe to submit my contact details?",
          content: ` Yes, all the details provided by you are completely safe and will not be shared with anytype of third party applications.
`
      },
      {
        title: "Is Weiw free?",
        content: `No, Weiw is a pay as you go service and we are trying to get platforms on board  which provide the free service.  `,
    },
    {
      title: "Is Weiw legal?",
      content: `Yes, Weiw is a legal service which works on a rental basis. `,
  },
  {
    title: "On which all devices can I access the plans taken from Weiw?",
    content: `The Weiw services can be accessed on any internet connected devices which offers ott applications. [Smart tv,  laptop/Pc, Mobile ,etc].`,
    },
    {
      title: "Can I cancel my order/get refunds on my orders?",
      content: ` Refundable is initiated only if the user hasn’t logged in with provided credentials.`,
    }
  ],
};

const styles = {
  bgColor: "transparent",
  rowTitleColor: "white",
  rowContentColor: 'grey',
  arrowColor: "red",
  textAlign: "left",
};

const config = {
   animate: true,
   arrowIcon: "+",
   tabFocus: true
};



function FAQ() {
  return (
    <div>
    <section className='about' id="faq">
        <Container>
            <Row
                style={{
                    marginTop: '30px',
                }}
            >
                <div className="col-md-6">
                <h1 style={{
                    textAlign: 'left',
                }}>Frequently Asked <br></br>Questions</h1>
                    <img src={faqHero} alt="aboutus" className="img-fluid about_img"
                    />
                </div>
                <div className="col-md-6"
                    style={{
                        marginTop: '100px',
                    }}
                >      
                   <Faq
                        data={data}
                        styles={styles}
                        config={config}
                  />
                </div>
            </Row>
        </Container>
    </section>  
</div>
  )
}

export default FAQ
