import React from 'react'

import { Container,Row,Col } from 'react-bootstrap';
import './Pricing.css';
import netflix from '../assets/img/netflix.webp';
import prime from '../assets/img/prime.webp';
import disney from '../assets/img/disney.webp'
import aha  from '../assets/img/aha.webp';
import zee5 from '../assets/img/zee5.webp';
import sonyliv from '../assets/img/sonyliv.webp';
import voot from '../assets/img/voot.webp';


function Pricing() {
  return (
    <>
        <section id='pricing'>
            <Container style={{textalign:"center"}}>
                <h1 style={{ textAlign: "center",padding:"10px",color:"rgb(220,218,212)" }}>
                    Resume the laze <span style={{ color: "#fed530"}}>o' clock</span> with the cine plan
                </h1>
                <div className="pricing-grid">
                    <div className="netflix pricing-card  text-center"
                        style={{
                            alignContent: "center",
                            alignItems: "center",
                            marginLeft:"35px",
                        }}
                    >
                        <img style={{width:"18vmax"}}
                          src={netflix}
                         alt="netflix" 
                     />
                     <p>Grab your</p>
                        <h6 className='bg-black px-5 py-3 fs-6 m-auto mb-3' style={{borderRadius:"8px",color:"#fed530"}}>
                         <span>
                         Beginner Plan <br/> (4 HRS)
                         </span>
                         </h6>
                     <p>at just</p>
                     <h1>
                         <span
                         style={{fontSize:""}}
                         >₹9/-</span>
                     </h1>
                     <button className="viewMore m-auto mb-1"
                         onClick={() => {
                             window.location.href = "/netflix-show";
                         }}
                     > View More</button>
                    </div>
                    <div className="prime-video pricing-card">
                        <div className="row">
                            <div className="col-6">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                >₹5/-</span>
                            </h1>
                            </div>
                            <div className="col-6">
                                <img style={{height:"5vmax",arginTop:""}}
                                    src={prime}
                                    alt="prime video"
                                    
                                />
                                <h6 className='bg-black px-2 py-2 m-auto mb-3' style={{borderRadius:"8px",color:"#fed530",fontSize:"13px"}}>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore m-auto"
                                    onClick={() => {
                                        window.location.href = "/prime-show";
                                    }}
                                > View More</button>
                            </div>
                        </div>
                    </div>
                    <div className="voot pricing-card">
                    <div className="row">
                            <div className="col-6">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                >₹3/-</span>
                            </h1>
                            </div>
                            <div className="col-6">
                                <img src={voot}
                                    alt="voot"
                                    style={{marginBottom:"10px",marginTop:"10px"}}
                                />
                                <h6 className='bg-black px-2 py-2 m-auto mb-3' style={{borderRadius:"8px",color:"#fed530",fontSize:"13px"}}>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore m-auto"
                                onClick={() => {
                                    window.location.href = "/voot-show";
                                }}> View More</button>
                            </div>
                        </div>
                    </div>
                    <div className="disney pricing-card">
                    <div className="row">
                            <div className="col-6">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                >₹5/-</span>
                            </h1>
                            </div>
                            <div className="col-6" style={{width:""}}>
                                <img
                                    src={disney}
                                    alt="disney plus hotstar"
                                    style={{width:"",margin:"5px 0px"}}
                                    
                                />
                               <h6 className='bg-black px-2 py-2 m-auto mb-3' style={{borderRadius:"8px",color:"#fed530",fontSize:"13px"}}>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore m-auto"
                                onClick={() => {
                                    window.location.href = "/hotstar-show";
                                }}> View More</button>
                            </div>
                        </div>
                    </div>
                    <div className="sonyliv pricing-card">
                    <div className="row">
                            <div className="col-6">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                >₹4/-</span>
                            </h1>
                            </div>
                            <div className="col-6">
                                <img
                                    style={{width:"8vmax", margin:"12px 0px",marginTop:"20px"}}
                                    src={sonyliv}
                                    alt="sonyliv"
                                />
                                <h6 className='bg-black px-2 py-2 m-auto mb-3' style={{borderRadius:"8px",color:"#fed530",fontSize:"13px"}}>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore m-auto"
                                onClick={() => {
                                    window.location.href = "/sonyliv-show";
                                }}> View More</button>
                            </div>
                        </div>
                    </div>
                    <div className="aha pricing-card">
                    <div className="row">
                            <div className="col-6">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                >₹4/-</span>
                            </h1>
                            </div>
                            <div className="col-6">
                                <img
                                    style={{width:"6vmax",margin:"18px 0px",marginTop:"30px"}}
                                    src={aha}
                                    alt="aha"
                                />
                                <h6 className='bg-black px-2 py-2 m-auto mb-3' style={{borderRadius:"8px",color:"#fed530",fontSize:"13px"}}>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore m-auto"
                                onClick={() => {
                                    window.location.href = "/aha-show";
                                }}> View More</button>
                            </div>
                        </div>
                    </div>
                    <div className="zee5 pricing-card">
                    <div className="row">
                            <div className="col-6">
                                <br></br>
                                <p>At just</p>
                            <h1>
                                <span
                                >₹4/-</span>
                            </h1>
                            </div>
                            <div className="col-6">
                                <img
                                    style={{width:"5vmax",margin:"10px 0px"}}
                                    src={zee5}
                                    alt="zee5"
                                />
                                <h6 className='bg-black px-2 py-2 m-auto mb-3' style={{borderRadius:"8px",color:"#fed530",fontSize:"13px"}}>
                                    Beginner Plan <br/> (4 HRS)
                                </h6>
                                <button className="viewMore m-auto" 
                                onClick={() => {
                                    window.location.href = "/zee5-show";
                                }}> View More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    </>
  )
}

export default Pricing

