import React,{ useEffect, useState } from 'react';
import "./MRow.css";
function MRow({title,movies,isLargeRow}) {
    return (
        <div className="row">
            {/* Title */}
            <h2 className="row__title">{title}</h2>
            {/* Container -> posters */}
            <div className="row__posters">
                {
                    movies.map(movie => (
                     <>
                            <img 
                            key={movie.id} 
                            className={`row__poster ${isLargeRow && "row__posterLarge"}`} 
                            src={movie.imglink} 
                            alt={movie.name}/>
                      </>
                    ))
                }
            </div>
        </div>
    )
}

export default MRow