import React from "react";
import Footer from "./Footer";
import { NavBar } from "./NavBar";
import hero from "../assets/img/person_with_pen.webp";

function AboutusPage() {
  return (
    <div>
      <NavBar />
      <div
        style={{
          backgroundColor: "RGB(220,218,212)",
          color: "black",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-md-6"
              style={{
                paddingTop: "150px",
              }}
            >
              <h2 style={{ fontFamily: '"Poppins"', fontWeight: 600 }}>
                Who we are....
              </h2>
              <p
                style={{
                  marginRight: "5rem",
                  lineHeight: "41.87px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize:"1em"
                }}
              >
                The word Weiw is a homophone of View. Weiw started with two
                enthusiastic puzzled personalities with their personal
                experience towards movies and series accessibility and managing
                on the available platforms and decided to make the entertainment
                accessible to everyone. In the calendar of survival, people
                hardly find a countable number of days to get chill, and one
                mode of chilling is with a movie or binge series. We provide a
                platform where users can find multiple different OTT platforms
                and get access to the account on an hourly basis
              </p>
              <br></br>
              <hr></hr>
            </div>
            <div
              className="col-md-6"
              style={{
                paddingTop: "100px",
                marginBottom: "10px",
              }}
            >
              <img src={hero} alt="illustartion" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6"
            >
              <h2 style={{ fontFamily: '"Poppins"', fontWeight: 600 }}>
                How do we do it
              </h2>
              <p
                style={{
                  marginRight: "6rem",
                  lineHeight: "41.87px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
              >
                Unlike the tedious approach of signing in with YOUR PERSONAL
                login credentials. We offer you a temporary login credentials
                immediately after payment. All you have to do is grab a snack
                and begin binging.
              </p>
            </div>
            <div className="col-md-6"
            >
              <h2
                style={{
                  fontFamily: '"Poppins"',
                  fontWeight: 600,
                  marginLeft: "0rem",
                }}
              >
                What we do
              </h2>
              <p
                style={{
                  marginRight: "rem",
                  marginLeft: "0rem",
                  lineHeight: "41.87px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
              >
                Weiw is a platform provided to those film junkies that want to
                watch new movies released or their favourite old rom com, but do
                not have access to it. We believe people should pay only for
                what they use. Its a budget friendly option for those who don't
                want a subscription to watch one or two movies. Our plans
                include options for various binging timeline. So that you can
                enjoy a nominal cost.
              </p>
            </div>
          </div>
          <div className="row"
            style={{
              marginTop: "50px",
              paddingBottom:"50px",
            }}
          >
            <div className="col-md-12 text-center">
              <h2>
              Resume the laze <span
                style={{
                  color:"#fdc801",
                  padding:"8px",
                  borderRadius:"15px",
                  backgroundColor:"#1b1b1b",
                }}
              >o’clock</span> with the cine plan
              </h2>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutusPage;