import React from 'react';
import './Banner.css';

function BannerComp(props) {
  return (
    <div>
        <header className='banner' style={{
          backgroundSize:"cover",
          backgroundImage:`url(${props.image})`,
          backgroundPosition:'center 0px',
          className:"img-fluid"
      }}>
          <div className='banner_contents'>
          <h1 className='banner_title'>
            {props.title}
          </h1>
          <h1 className='banner_description'>
            {props.description}
         </h1>
          <div className='banner_buttons'>
            <a href={props.BannerCompLink}>
              <button className='banner_button'>Watch Now</button>
            </a>
          </div>
          </div>
   
      </header> 
    </div>
  )
}

export default BannerComp
