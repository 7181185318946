import React from 'react';
import './PageBanner.css';

function PageBannerComp(props) {
  return (
    <div>
        <header className='pbanner' style={{
          backgroundSize:"cover",
          backgroundRepeat:"no-repeat",
          backgroundImage:`url(${props.image})`,
          backgroundPosition:"center",
      }}>
          <div className='pbanner_contents'>
            <img src={props.logoLink} alt='Logo' className='pbanner_logo'/>
          <h1 className='pbanner_title'>
            {props.title}
          </h1>
          <h1 className='pbanner_description'>
            {props.description}
         </h1>
          <div className='pbanner_buttons'>
            <a href="#form">
              <button className='banner_button'>Buy Now</button>
            </a>
          </div>
          </div>
      </header> 
    </div>
  )
}

export default PageBannerComp
