import React from 'react';
import './Footer.css';
import { CDBBox } from 'cdbreact';
import {FaArrowRight,FaInstagram,FaLinkedin,FaTwitter,FaUserAlt,FaLock,FaWhatsapp} from "react-icons/fa";
import {BiCameraMovie,BiSlideshow } from "react-icons/bi";
import { GrMail } from "react-icons/gr";
import emailjs from '@emailjs/browser';
import popcorn from '../assets/img/whitepopcorn.webp';
//import logo from '../assets/img/popcorn-logo.png'

export const Footer = () => {
  var writeUs="";
  var currpath = window.location.pathname;
  console.log(currpath);
  const handleWriteUs = (e) => { 
    if(writeUs.length===0){
      alert('Please write us something..');
    }
    if(writeUs.length>0){
      emailjs.send('service_4jtpdbd', 'template_v1c967p',{
        message: writeUs,
       }, 'EhoVPfwYSjL3vQstf')
      .then((result) => {
          alert('Thank you for your feedback!');
          //console.log(result.text);
          window.location.href = currpath;
      }, (error) => {
          console.log(error.text);
      });
    }
    
}; 
  return (
    <section id='footer'>
      <div className="p-3 bg">
      <CDBBox display="flex" flex="column" className="mx-auto py-5" style={{ width: '90%' }}>
        <CDBBox display="flex" justifyContent="between" className="flex-wrap">
          <CDBBox>
            <a href="/" className="d-flex align-items-center p-0 text-decoration-none">
              <img src={popcorn} alt="logo" style={{width:"85px"}} />
              <span className="ml-3 h1 pally" style={{color:'#fed530'}}>weiw your <br/> Favourites!</span>
            </a>
            <p className="my-2 text-center" style={{fontSize:'small',color: 'rgb(220,218,212'  }}>
              Lend subscriptions with economical bill. <br/> Expenses are inexpensive with Weiw.
            </p>

            <textarea name="mail" id="mail" placeholder='Write us..' style={{backgroundColor:'#525252',width:'250px'}} className='p-2 mt-2 rounded text-white border-0'
              onChange={(e)=>{writeUs=e.target.value}}></textarea>
            <span className=''><button className='rounded ms-2 p-3 pb-4 border-0' style={{backgroundColor:'#fed530', position:'absolute',marginTop:'8.5px'}}
            onClick={
              (e) => handleWriteUs(e)
            }
            ><FaArrowRight size='1.2rem'/></button></span>
          </CDBBox>
          <CDBBox>
            <p className="h6 mb-4 mt-3 ms-2" style={{ fontWeight: '800', color: 'rgb(220,218,212' }}>
              Products
            </p>
            <CDBBox className='footer-options ms-2' style={{ cursor: 'pointer', padding: '0' }}>
              <a href="/" style={{display:"block"
              }}><BiCameraMovie size='1.1rem'/> Movies</a>
              <a href="/" style={{display:"block"
              }}><BiSlideshow size='1.1rem'/> Shows</a>
            </CDBBox>
          </CDBBox>
          <CDBBox>
            <p className="h6 mb-4 mt-3 ms-2" style={{ fontWeight: '800', color: 'rgb(220,218,212'  }}>
              Follow us on
            </p>
            <div className='footer-options ms-2' style={{ cursor: 'pointer', padding: '0' }}>
              <a href="https://instagram.com/weiw_in?igshid=ZDdkNTZiNTM=/" target="_blank" style={{display:"block"
              }}><FaInstagram/> Instagram</a>
              <a href="https://www.linkedin.com/company/weiwin/" target="_blank" style={{display:"block"
              }}><FaLinkedin/> Linkedin</a>
              <a href="/" target="_blank" style={{display:"block"
              }}><FaTwitter/> Twitter</a>
            </div>
          </CDBBox>
          <CDBBox>
            <p className="h6 mb-4 mt-3 ms-2" style={{ fontWeight: '800', color: 'rgb(220,218,212' }}>
            Help & Support
            </p>
            <CDBBox className='footer-options ms-2' style={{ cursor: 'pointer', padding: '0' }}>
              <a href="/about-us" style={{display:"block"
              }}><FaUserAlt size='0.9rem'/> About</a>
              <a href="/" style={{display:"block"
              }}><FaLock size='0.9rem'/> Privacy Policy</a>
            </CDBBox>
          </CDBBox>
        </CDBBox>
        <p  className="mt-5" style={{fontWeight:'100' ,color: 'rgb(220,218,212', display:'flex', justifyContent:'center'}}> 
        <span style={{display:"block"}}><GrMail/> reach@weiw.in</span> <span className='ms-5' style={{display:"block"}}><FaWhatsapp/> +91 8123194272</span></p>
        <small className="text-center mt-4" style={{fontWeight:'100' ,color: 'rgb(220,218,212' }}>&copy; COPYRIGHT © 2023 WEIW - ALL RIGHTS RESERVED.</small>
      </CDBBox>
    </div>
    </section>
  );
};
export default Footer;