import React from "react";
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
import "./ProgressB.css";
import paymentQR from "../assets/img/paymentQR.webp";
import copy from "copy-to-clipboard";
import orderConfirmation from "./order.png";
import emailjs from '@emailjs/browser';


export const ProgressB = (props) => {
  var ott=props.ott;
  var name = "";
  var email = "";
  var number = "";
  var plan = "";
  var isAgree = false;

  const copyToClipboard = () => {
    copy("The Maze Stuff");
    alert(`Thank you for using weiw, we are currently not serving any OTT platforms, for Entertainment checkout our Youtube "The Maze Stuff"`);
  };

  const handleCheckBoxChange = (e) => { 
       if(name!=="" && email!=="" && number!=="" && plan!==""){
          isAgree=(!isAgree); 
          emailjs.send('service_4jtpdbd', 'template_ks351cq',{
            ott: ott,
            name: name,
            email: email,
            number: number,
            plan: plan,
          }, 'EhoVPfwYSjL3vQstf')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
       }
       else{
          (e.target.checked=false);
          alert("Please fill all the details");
       }
  }; 
  const step1Content = (
    <div id="form"
      style={{
        padding: "25px",
      }}
    >
      <h4 className="heading_weiw">Weiw</h4>
      <h6>Please enter your details</h6>
      <br></br>
      <input
        type="text"
        className="form-control inputcss"
        id="name"
        placeholder="Name"
        required={true}
        onChange={(e) => name=(e.target.value)}
      />
      <input
        type="text"
        className="form-control inputcss"
        id="email"
        placeholder="Email"
        required={true}
        onChange={(e) => email=(e.target.value)}
      />
      <input
        type="text"
        className="form-control inputcss"
        id="number"
        placeholder="Contact no."
        required={true}
        onChange={(e) => number=(e.target.value)}
      />
      <select
        className="form-control"
        id="exampleFormControlSelect1"
        style={{
          backgroundColor: "rgba(82, 82, 82, 1)",
          color: "whitesmoke",
          border: "none",
        }}
        required={true}
        onChange={(e) => plan=(e.target.value)}
      >
        <option  value="default">Choose plans</option>
        <option className="option_font" value={props.beginnerPrice} >Beginner -- 4 Hours -- {props.beginnerPrice}/-</option>
        <option className="option_font" value={props.cinephilePrice}>Cinephile -- 12 Hours -- {props.cinephilePrice}/-</option>
        <option className="option_font" value={props.bingePrice}>Binge -- 20 Hours -- {props.bingePrice}/-</option>
      </select>
      <div className="form-check" style={{ marginTop: 15 }}>
        <input
          className="form-check-input"
          type="checkbox"
          id="flexCheckDefault"
          defaultValue=""
          style={{
            backgroundColor: "#e4be24",
            color: "black",
            borderColor: "#e4be24",
          }}
          required={true}
          onChange={
            (e) => handleCheckBoxChange(e)
          }
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          I agree to the terms and conditions
        </label>
      </div>
    </div>
  );
  const step2Content = (
    <div
      style={{
        padding: "25px",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <h4 className="heading_weiw"> Weiw</h4>
      <h6>Please pay using below methods</h6>
      <hr></hr>
      <img src={paymentQR} />
      <br></br>
      <h6
        style={{
          textAlign: "center",
        }}
      >
        OR
      </h6>
      <div className="input-group mb-2">
        <input
          type="text"
          className="form-control"
          id="inlineFormInputGroup"
          placeholder="The Maze Stuff"
          readOnly={true}
          style={{
            background: "none",
            borderRadius: "0px",
            color: "white",
            fontWeight: "bolder",
          }}
        />

        <div className="input-group-prepend">
          <button
            className="btn btn-outline-secondary"
            type="button"
            id="button-addon1"
            style={{
              borderRadius: "0px",
              borderColor: "white",
              backgroundColor: "#fed530",
              color: "black",
            }}
            onClick={copyToClipboard}
          >
            Copy
          </button>
        </div>
      </div>
    </div>
  );
  const step3Content=(
    <div
        style={{
        padding: "25px",
        }}
    >
      <h4 className="heading_weiw"> Weiw</h4>
      <h6>Order Confirmation</h6>
      <hr></hr>
        <img src={orderConfirmation} />
    </div>
  ); 
  const step1Validator = () => {
        if(name==="" || email==="" || number==="" || plan==="" || isAgree===false){
            alert("Please fill all the details and agree to the terms and conditions");
            return false;
        }
        else{
            return true;
        }
    };

  function onFormSubmit() {
    // redirect to anathor page
    window.location.href = "/netflix-show";
  }

  return (
    <>
      <StepProgressBar
        startingStep={0}
        onSubmit={onFormSubmit}
        steps={[
          {
            label: "Your Details",
            name: "Your Details",
            content: step1Content,
            validator: step1Validator,
          },
          {
            label: "Payment",
            name: "Payment",
            content: step2Content, 
          },
          {
            label: "Done",
            name: "Done",
            content: step3Content,
          }
        ]}
      />
    </>
  );
};
