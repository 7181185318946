import React from 'react';
import './Banner.css';
import BannerComp from './BannerComp';
import tnm from "../assets/img/banners/tnm.jpeg"
import stnm from "../assets/img/banners/Stnm.jpeg"
import wed from "../assets/img/banners/wednesday.jpg"
import swed from "../assets/img/banners/swed.jpg"
import farzi from "../assets/img/banners/farzi.jpg"
import sfarzi from "../assets/img/banners/sfarzi.jpg"
import ssr from "../assets/img/banners/ssr.jpeg"
import sr from "../assets/img/banners/sr.jpeg"
function Banner() {

    // generate random number between 1 and 4
    const seq = Math.floor(Math.random() * 4) + 1;

    return ( 
        <div>
            <section className="banner" id="home">
        {seq === 1 && (
          <BannerComp
           // if window size is less than 991px then image will be large else small
            image={
              window.innerWidth > 700 ? tnm : stnm
            }
            title="The Night Manager"
            description="Follows Jonathan, an ex-soldier, who must infiltrate an arms dealer's inner circle to avenge the death of his girlfriend."
            BannerCompLink = "/hotstar-show" />
        )}
        {seq === 2 && (
          <BannerComp
          image={
            window.innerWidth > 991 ? wed : swed
          }
            title="Wednesday"
            description="Follows Wednesday Addams' years as a student, when she attempts to master her emerging psychic ability, 
            thwart a killing spree, and solve the mystery that embroiled her parents."
            BannerCompLink = "/netflix-show"
          />
        )}
        {seq === 3 && (
          <BannerComp
          image={
            window.innerWidth > 850 ? farzi : sfarzi
          }
            title="Farzi"
            description="An artist who gets pulled into the murky high stakes of a con job and a fiery task force officer on the mission to rid the country of his menaces in a fast-paced,
             edgy one-of-a-kind thriller."
            BannerCompLink = "/prime-show" />
        )}
        {seq === 4 && (
          <BannerComp
          image={
            window.innerWidth > 700 ? sr : ssr
          }
            title="Setha Ramam"
            description="An orphan soldier, Lieutenant Ram's life changes, after he gets a letter from a girl named Sita. He meets her and love blossoms between them."
            BannerCompLink = "/prime-show"
          />
        )}
      </section>
        </div>
    )
}

export default Banner






// {
//     window.innerWidth > 991 ?  <section className = "banner"
//     id = 'home' > {
//         seq === 1 &&
//         <BannerComp
//         image = "https://bilder.fernsehserien.de/sendung/hr2/farzi-die-geldfaelscher_808574.jpg"
//         title = "Farzi"
//         description = "An artist who gets pulled into the murky high stakes of a con job and a fiery task force officer on the mission to rid the country of his menaces in a fast-paced, edgy one-of-a-kind thriller."
//         BannerCompLink = "/prime-show" />
//     } {
//         seq === 2 &&
//             <BannerComp
//         image = "https://occ-0-2042-2186.1.nflxso.net/dnm/api/v6/6AYY37jfdO6hpXcMjf9Yu5cnmO0/AAAABXD7NkdYF6ysn-dSMKaWhLIUX2Kbm1KJdTbDKh679kPwUMuTWYNrFz_Nh3EvvyXi4Zuiz9WXMTHPPk49o3EqRRF1kS7QybxJNsL9.jpg?r=d6c"
//         title = "Venom: Let There Be Carnage"
//         description = "While Eddie Brock and Venom try to navigate their thorny relationship, a new threat rises from serial killer Cletus Kasady and a new alien symbiote."
//         BannerCompLink = "/netflix-show" />
//     } {
//         seq === 3 &&
//             <BannerComp
//         image = "https://occ-0-2042-2186.1.nflxso.net/dnm/api/v6/6AYY37jfdO6hpXcMjf9Yu5cnmO0/AAAABU6h2p8-SwxdOFky53UshrIlRU0UZ1i09P_Q_mFM5bjehCRk_dwCmDOUpMK4wnOkNO97heuKpEhmgkYRDxAPd6gWqMvwZrHvwVae.jpg?r=d21"
//         title = "Love Today"
//         description = "Before they get married, a couple must swap phones for 24 hours at the behest of the bride-to-be's suspicious father."
//         BannerCompLink = "/netflix-show" />
//     } {
//         seq === 4 &&
//             <BannerComp
//         image ={tnm}
//         title = "The Night Manager"
//         description = "Follows Jonathan, an ex-soldier, who must infiltrate an arms dealer's inner circle to avenge the death of his girlfriend."
//         BannerCompLink = "/hotstar-show" />
//     } 
//     </section> : 
//     // mobile size banner vertical pics
//     <section className = "banner"
//     id = 'home' > {
//         seq === 1 &&
//         <BannerComp
//         image = "https://wallpapercave.com/wp/wp7709083.jpg"
//         title = "The Witcher"
//         description = "Geralt of Rivia, a solitary monster hunter, struggles to find his place in a world where people often prove more wicked than beasts."
//         BannerCompLink = "/netflix-show" />
//     } {
//         seq === 2 &&
//             <BannerComp
//         image = "https://m.media-amazon.com/images/M/MV5BZDYyMGIyZGQtZjFjOS00YjRmLTgwMWItZjAwODI4ZTNiYzUxXkEyXkFqcGdeQXVyOTI3MzI4MzA@._V1_.jpg"
//         title = "Farzi"
//         description = "An artist who gets pulled into the murky high stakes of a con job and a fiery task force officer on the mission to rid the country of his menaces in a fast-paced, edgy one-of-a-kind thriller."
//         BannerCompLink = "/prime-show" />
//     } {
//         seq === 3 &&
//             <BannerComp
//         image = "https://occ-0-2042-2186.1.nflxso.net/dnm/api/v6/6AYY37jfdO6hpXcMjf9Yu5cnmO0/AAAABU6h2p8-SwxdOFky53UshrIlRU0UZ1i09P_Q_mFM5bjehCRk_dwCmDOUpMK4wnOkNO97heuKpEhmgkYRDxAPd6gWqMvwZrHvwVae.jpg?r=d21"
//         title = "Love Today"
//         description = "Before they get married, a couple must swap phones for 24 hours at the behest of the bride-to-be's suspicious father."
//         BannerCompLink = "/netflix-show" />
//     } {
//         seq === 4 &&
//             <BannerComp
//         image = {stnm}
//         title = "Godzilla vs. Kong"
//         description = "Follows Jonathan, an ex-soldier, who must infiltrate an arms dealer's inner circle to avenge the death of his girlfriend."
//         BannerCompLink = "/hotstar-show" />
//     } 
//     </section>
//    }