import React, { Component } from "react";
import { NavBar } from "./NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MRow from "./MRow";
import "./PageBanner.css";
import PageBannerComp from "./PageBannerComp";
import { ProgressB } from "./ProgressB";
import Footer from "./Footer";
import sonyliv from "../assets/img/sonyliv.webp";
import rocketboys from "../assets/img/banners/rocketboys.jpeg"
import srocketboys from "../assets/img/banners/srocketboys.jpeg"
import clgRoman from "../assets/img/banners/clgRoman.jpeg"
import sclgRoman from "../assets/img/banners/sclgRoman.jpeg"
import gd from "../assets/img/banners/gooddoc.jpeg"
import ooj from "../assets/img/banners/ooj.jpg"
import sooj from "../assets/img/banners/sooj.jpg"
function Sonyliv() {
  // generate random number between 1 and 4
  const seq = Math.floor(Math.random() * 4) + 1;

  return (
    <div>
      <NavBar />
      <section className="pbanner" id="home">
        {seq === 1 && (
          <PageBannerComp
          image={
            window.innerWidth > 600 ? rocketboys : srocketboys
          }
            title="Rocket Boys 2"
            description="The story of two extraordinary men, Dr. Homi Jehangir Bhabha and Dr. Vikram Ambalal Sarabhai, who created history while building India's future."
            BannerCompLink="#buy"
            logoLink={sonyliv}
          />
        )}
        {seq === 2 && (
          <PageBannerComp
          image={
            window.innerWidth > 800 ? clgRoman : sclgRoman
          }
            title="College Romance"
            description="Three best friends look for love, laughs and some lifelong memories while attending college together."
            BannerCompLink="#buy"
            logoLink={sonyliv}
          />
        )}
        {seq === 3 && (
          <PageBannerComp
          image={
            window.innerWidth > 600 ? gd : gd
          }
          title="The Good Doctor"
            description="Shaun Murphy, a young surgeon with autism and Savant syndrome, is recruited into the surgical unit of a prestigious hospital."
            logoLink={sonyliv}
          />
        )}
        {seq === 4 && (
          <PageBannerComp
          image={
            window.innerWidth > 700 ? ooj : sooj
          }
            title="Oke Oka Jeevitham"
            description="To set their present right, Aadhi and his friends decide to take a ride with time to set their past right through a scientist. But time had other plans for them."
            BannerCompLink="#buy"
            logoLink={sonyliv}
          />
        )}
      </section>
      <div
        className=""
        style={{
          marginTop: "20px",
          marginLeft: "20px",
        }}
      >
        <MRow
          title="New Releases"
          movies={[
            {
              id: 1,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BM2U0OWVkMTAtNDg4NC00NTBhLWFiOGYtMDljNzkyOGRhMzUzXkEyXkFqcGdeQXVyOTIxMzQ0NzU@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 2,
              
              imglink:
                "https://images.slivcdn.com/videoasset_images/nijamwithsmitha_2_portrait_thumb_newshow.jpg?h=494&w=344&t=c_fill&q=low&fr=webp",

            },
            {
              id: 3,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjFhYzlkMTEtNGE4NS00ZWI1LWE2Y2YtMmMxYmMwNWIxN2RjXkEyXkFqcGdeQXVyOTIxMzQ0NzU@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 4,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BY2MwNWYwMDItOTY1MC00ZTQ4LWI0OTUtNzBhMWViM2ZjYzY5XkEyXkFqcGdeQXVyODAzNzAwOTU@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 5,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BN2Y2ZWExNDUtMTExNC00NDI1LWI3NWYtZDU3MGQ0NTEzYThmXkEyXkFqcGdeQXVyOTQxNzY1NTE@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 6,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZmE3YmY0NGEtNDIyNy00ZDE5LTk2ZDYtMGFmMDNiZTcwNDg1XkEyXkFqcGdeQXVyMTEzNzg0Mjkx._V1_.jpg",

            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMGFjNDkxMzgtMTEyNS00MzU0LTljZWMtZGQzYmQ5M2E4ZmIyXkEyXkFqcGdeQXVyMTY0NzczOTg@._V1_.jpg",

            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMjUxOGE2NmYtZTQ4YS00OGJjLTkzNWQtNmVkMWE4NDI5NTY0XkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_.jpg",

            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNDlkNzI2MWUtYTkyOS00MTkxLTg0YzctZmQwNjExYjQzOTc1XkEyXkFqcGdeQXVyMTExMTIzMTA5._V1_.jpg",

            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BY2I2YWY1MjctNzgzZC00NGZlLTkzMjEtODM5MWE5NWM0NzAyXkEyXkFqcGdeQXVyMTU2NDkwOTAw._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 11,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMmIyYTdjN2ItYTg3Ni00YmRkLWFiMzAtNjU1ZjVhNzk3ZDM2XkEyXkFqcGdeQXVyNTgxODY5ODI@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 12,
              
              imglink:
                "https://assets.gadgets360cdn.com/pricee/assets/product/202207/Meme-Boys-poster_1658334591.jpg",

            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Movies"
          movies={[
            {
              id: 1,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNzRlNDJmOWUtMTM5Ni00ZjNlLWEwZjYtNTY1ZGEzNjE3OWMzXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_.jpg",

            },
            {
              id: 2,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOTdmNTFjNDEtNzg0My00ZjkxLTg1ZDAtZTdkMDc2ZmFiNWQ1XkEyXkFqcGdeQXVyNTAzNzgwNTg@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 3,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMjUxOGE2NmYtZTQ4YS00OGJjLTkzNWQtNmVkMWE4NDI5NTY0XkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_.jpg",

            },
            {
              id: 4,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTYzOTE2NjkxN15BMl5BanBnXkFtZTgwMDgzMTg0MzE@._V1_.jpg",

            },
            {
              id: 5,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTY1NzM4NTM3Nl5BMl5BanBnXkFtZTgwNzgzNjA1NTE@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 6,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTQzNzczMDUyNV5BMl5BanBnXkFtZTcwNjM2ODEzOA@@._V1_.jpg",

            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNjAzOTUzNTY3Ml5BMl5BanBnXkFtZTgwMjYwNzE5ODE@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOGI0MzkyZGYtNDg5MC00MTA0LTk0OWEtOTRhMmIyYzhmYTBjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMjExNzkxOTYyNl5BMl5BanBnXkFtZTcwODA0MjU4Ng@@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZGM2MDQ4OTUtNjI3Mi00OTNiLWJhYmYtOTdiN2JiYTNmN2Q0XkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 11,
              
              imglink:
                "",

            },
            {
              id: 12,
              
              imglink:
                "",

            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Shows"
          movies={[
            {
              id: 1,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOTViZTI3MDItYzJjNi00ZTE0LWI0MTctY2FlMzY3MGI2ZTAyXkEyXkFqcGdeQXVyNjc3MjQzNTI@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 2,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNjgxZTMxNmQtZGFkZS00NDUyLTllMzYtOTE0ZjdlMjM1ZWU0XkEyXkFqcGdeQXVyMTAyMTE1MDA1._V1_.jpg",

            },
            {
              id: 3,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNDlkNzI2MWUtYTkyOS00MTkxLTg0YzctZmQwNjExYjQzOTc1XkEyXkFqcGdeQXVyMTExMTIzMTA5._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 4,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMTViMDJlYzEtOTUxNi00ZTU5LWI5MTYtMGViNTQ4NTVkYTMyXkEyXkFqcGdeQXVyMzAzNTY3MDM@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 5,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZjBkZTZjMzQtZDJhMS00MzE3LTg0MDctZjZhZDhhYjFlODZjXkEyXkFqcGdeQXVyOTIxMzQ0NzU@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 6,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BN2QwZmNhZjctZDZkMi00Mjk2LTg4OWMtNGEzMjk1ZWIwN2IzXkEyXkFqcGdeQXVyNDY4NjAxNTc@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 7,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNDI0YzMwMDktNzc1OS00N2QyLWFlNDMtZWJlODU2MjQ1YThiXkEyXkFqcGdeQXVyMTAyMTE1MDA1._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 8,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNjk5NjNlNzctYTg5OC00M2RjLWE2NGQtOTc0NTViNTc5YWZhXkEyXkFqcGdeQXVyNDAzNDk0MTQ@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 9,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNDU3OWVjOGMtMzA3Zi00NDNmLWFhYTItODZmZDk1MzQ1MjUzXkEyXkFqcGdeQXVyOTIxMzQ0NzU@._V1_FMjpg_UX1000_.jpg",

            },
            {
              id: 10,
              
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNGZlODY0MzUtZDFlMi00YjBkLWE1ZTctZjU3ZjU3ODg1ZDY2XkEyXkFqcGdeQXVyNjQ2MjQ4MzQ@._V1_.jpg",

            },
            {
              id: 11,
              
              imglink:
                "",

            },
            {
              id: 12,
              
              imglink:
                "",

            },
          ]}
          isLargeRow={true}
        />
      </div>
      <div
        classsName="col-md-11"
        style={{
          marginTop: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ProgressB
            ott="Sony Liv"
            beginnerPrice="4"
            cinephilePrice="6"
            bingePrice="8"
        />        
      </div>
      <section id="buy">
      <a
        href="/#faq"
        className="any_queries"
        style={{
            textDecoration: "none",
            color: "white",
            marginTop: "5px",
            marginBottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: "10px",
          }}
      >Any queries?</a>
      </section>
      <Footer/>
    </div>
  );
}

export default Sonyliv;
