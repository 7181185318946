import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from "react-router-dom" 
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Home from './Home';
import Netflix from './components/Netflix';
import Prime from './components/Prime';
import Hotstar from './components/Hotstar';
import Sonyliv from './components/Sonyliv';
import Aha from './components/Aha';
import Voot from './components/Voot';
import Zee from './components/Zee';
import AboutusPage from './components/AboutusPage';

function App() {

  return (
    <div className="App" style={{backgroundColor:'#1b1b1b'}}>
    <Router>
      <Routes>
        <Route eact path="/" element={<Home/>} />
        <Route path="/netflix-show" element={<Netflix/>} />
        <Route path="/prime-show" element={<Prime/>}  />
        <Route path="/hotstar-show" element={<Hotstar/>} />
        <Route path="/sonyliv-show" element={<Sonyliv/>} />
        <Route path="/aha-show" element={<Aha/>} />
        <Route path="/voot-show" element={<Voot/>} />
        <Route path="/zee5-show" element={<Zee/>} />
        <Route path='/about-us' element={<AboutusPage/>} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
