import React, { Component } from "react";
import { NavBar } from "./NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MRow from "./MRow";
import "./PageBanner.css";
import PageBannerComp from "./PageBannerComp";
import { ProgressB } from "./ProgressB";
import Footer from "./Footer";
import aha from "../assets/img/aha.png";
import pages from "../assets/img/banners/18pages.jpeg";
import pagesSmall from "../assets/img/banners/spages.jpeg";
import unstop from "../assets/img/banners/unstop.jpeg";
import sunstop from "../assets/img/banners/sunstop.jpg";
import masooda from "../assets/img/banners/masooda.jpg";
import smasooda from "../assets/img/banners/smasooda.jpeg";
function Aha() {
  // generate random number between 1 and 4
  const seq = Math.floor(Math.random() * 3) + 1;

  return (
    <div>
      <NavBar />
      <section className="pbanner" id="home">
        {seq === 1 && (
          <PageBannerComp
          image={
            window.innerWidth > 700 ? unstop : sunstop
          }
            title="Unstoppable"
            description="The Show NANDAMURI BALAKRISHNA interacts with other celebrities and colleagues in the Telugu Film Industry called Tollywood."
            BannerCompLink="#buy"
            logoLink={aha}
          />
        )}
        {seq === 2 && (
          <PageBannerComp
          image={
            window.innerWidth > 700 ? masooda : smasooda
          }
            title="Masooda"
            description="Asingle mother, and Nazia, her daughter, take a horrific turn when an evil force enters into their lives. 
            The story revolves around how Gopi, their helpful neighbor, and Neelam face the odds and save Nazia."
            BannerCompLink="#buy"
            logoLink={aha}
          />
        )}
        {seq === 3 && (
          <PageBannerComp
          image={window.innerWidth>700 ? pages:pagesSmall}
            title="18 Pages"
            description="Nandini, a young woman who suffers from short-term memory loves to use a diary to keep track of her life. 
            But, when her diary is lost, it is found by a young man. Drawn to the person he reads about, he sets out to find her."
            BannerCompLink="#buy"
            logoLink={aha}
          />
        )}
        
      </section>
      <div
        className=""
        style={{
          marginTop: "20px",
          marginLeft: "20px",
        }}
      >
        <MRow
          title="New Releases"
          movies={[
            {
              id: 1,
              imglink:
                "https://images.news18.com/telugu/uploads/2023/01/Pawan-and-Balakrishna-.jpeg?im=Resize,width=904,aspect=fit,type=normal",
            },
            {
              id: 2,
              imglink:
                "https://static.netnaija.com/i/9bVNrZWqNWv.jpg",
            },
            {
              id: 3,
              imglink:
                "https://images.justwatch.com/poster/23397159/s592/hwarang-the-poet-warrior-youth",
            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjYxMTNlMmMtZTY2ZC00MTY2LTkwMzAtMzRiNjE3YzgxYmU1XkEyXkFqcGdeQXVyMTUzNTgzNzM0._V1_.jpg",
            },
            {
              id: 5,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYWYzYTRkZGQtMjViZC00NTY2LWFkNTktN2Y1ZGQzYjJiNWNiXkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_.jpg",
            },
            {
              id: 6,
              imglink:
                "https://staticimg.spicyonion.com/images/profile/title/hunt-telugu-movie/hunt-telugu-movie.jpg",
            },
            {
              id: 7,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNGMyNTBlZjMtNjE3NS00NmU2LThmZGItZWMxMWExYWE1ZDQwXkEyXkFqcGdeQXVyMTU2NDkwOTAw._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 8,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYWYxZTlkZjItNTEwMi00YmQzLWIzN2YtMmQ2MjhiYWEyOTY0XkEyXkFqcGdeQXVyMTU2NDkwOTAw._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 9,

              imglink:
                "https://assets.mspimages.in/wp-content/uploads/2022/12/NBK7.jpeg",
            },
            {
              id: 10,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYzcyMDQzOTQtNmY3OC00MDgyLTg5MTQtZTZmMmUxYTlmNjVjXkEyXkFqcGdeQXVyMTI1NDEyNTM5._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 11,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYjNlOWQ2YzEtYTc5Yy00MjcyLTg2YTgtMGVmM2YzZTk4N2JjXkEyXkFqcGdeQXVyMTUzOTcyODA5._V1_.jpg",
            },
            {
              id: 12,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BY2Q5ZGU2MGUtOWZlMS00OTUwLWIyYjAtOGFkZTM3MDc2MWY5XkEyXkFqcGdeQXVyMTI1NDEyNTM5._V1_FMjpg_UX1000_.jpg",
            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Movies"
          movies={[
            {
              id: 1,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMzQxNDhjODMtNGM3Yy00ZWI5LWFlYjItZWM2N2ZkMDgwZDQxXkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_.jpg",
            },
            {
              id: 2,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYmNjMWJiMmQtOWY2OS00YzQ2LWFjZjEtNDVkMTE2ZTNkYTZkXkEyXkFqcGdeQXVyMTUwMDg3OTQy._V1_.jpg",
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYzUxZDIyYmMtNGNhYi00NDU5LWE0MGYtZDI3ODIzOWI1NjE3XkEyXkFqcGdeQXVyMTQ4ODU3Mjc1._V1_.jpg",
            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNDFmNDk3MWMtMDY0NS00ZGFmLTlkYTUtNmUwYTU4YzllNTNhXkEyXkFqcGdeQXVyNTgxODY5ODI@._V1_.jpg",
            },
            {
              id: 5,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMjI3Yzc2MGEtNDc0Ni00ZjkwLTlmYjEtMmQzMGEyNmQ1ZmQ4XkEyXkFqcGdeQXVyMTE1MjcwOTA4._V1_.jpg",
            },
            {
              id: 6,
              imglink:
                "https://www.wishesh.com/images/reviews/Khaidi-Movie-Review.jpg",
            },
            {
              id: 7,
              imglink:
                "https://popcornreviewss.com/wp-content/uploads/2021/05/Ok-Kanmani-2015-Romantic-Tamil-Movie-Review.jpg",
            },
            {
              id: 8,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYzQ2NmRlMDQtZWJjZi00MjQ0LTg2MjYtZjQ1YzZmMjdiNmRjXkEyXkFqcGdeQXVyNTgxODY5ODI@._V1_.jpg",
            },
            {
              id: 9,
              imglink:
                "https://www.wishesh.com/images/reviews/Khaidi-Movie-Review.jpg",
            },
            {
              id: 10,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOWUxZmI0ZWMtOTQxYy00NDdmLThhOTgtNmUzM2Q5ZGI1MWM4XkEyXkFqcGdeQXVyMjUxMTY3ODM@._V1_.jp",
            },
            {
              id: 11,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZTE0NTZmOWUtOTc2My00YmM5LTk4Y2QtOGEyYTVhZDY4NDk0XkEyXkFqcGdeQXVyMTEzNzg0Mjkx._V1_.jpg",
            },
            {
              id: 12,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNTQ5NjU0MTctMzc3NC00MTM2LTg1ZWItMGQwZDYyMTFiZjc3XkEyXkFqcGdeQXVyMjAzMjcxNTE@._V1_.jpg",
            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Shows"
          movies={[
            {
              id: 1,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOWQ2MmI0YWEtNDczMS00ZDY2LTkyMjMtN2Q0NTY3MTA3ZDAzXkEyXkFqcGdeQXVyMTI1NDEyNTM5._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 2,
              imglink:
                "https://timesofindia.indiatimes.com/thumb/92601649.cms?width=219&height=317&quality=80&imgsize=14072",
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOTZkODNhNDgtZjAyOC00NDQ0LWIwN2QtMTM1ZTgzMGMzN2FlXkEyXkFqcGdeQXVyMTI1NDEyNTM5._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 4,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYTRkMzJmNDMtN2I3NC00MzFkLWI1Y2MtNjdjODZjMmY0NTJmXkEyXkFqcGdeQXVyMTU3NDgzNzM4._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 5,
              imglink:
                "https://cdn.celpox.com/bby_uploads/media/eb5f59d855f904a4791e33986f993bf1.jpg",
            },
            {
              id: 6,
              imglink:
                "https://images.justwatch.com/poster/245548344/s592/sam-jam",
            },
            {
              id: 7,
              imglink:
                "https://images.justwatch.com/poster/243596259/s332/season-3.%7Bformat%7D",
            },
            {
              id: 8,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BNTAxYjBiOTQtZmJjZS00MTg5LWIzNDgtODZlNzE2MTY5MjBlXkEyXkFqcGdeQXVyMTUyNzk0MzIw._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 9,
              imglink:
                "https://images.justwatch.com/poster/270308651/s718/telugu-indian-idol.%7Bformat%7D",
            },
            {
              id: 10,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMjkwYmI0MzQtOTMyNy00Y2RlLWE0ZWUtNTcwNWE5ZTU0NjAwXkEyXkFqcGdeQXVyMTI1NDAzMzM0._V1_.jpg",
            },
            {
              id: 11,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BMzU0ZWE4ZDgtZGQ4Ny00ZDliLTg1NDEtMzg5MmFlNzllNTNiXkEyXkFqcGdeQXVyMTE3MDUzOTk0._V1_.jpg",
            },
            {
              id: 12,
              imglink:
                "",
            },
          ]}
          isLargeRow={true}
        />
      </div>
      <div
        classsName="col-md-11"
        style={{
          marginTop: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ProgressB
            ott="Aha"
            beginnerPrice="4"
            cinephilePrice="6"
            bingePrice="8"
        />        
      </div>
      <section id="buy">
      <a
        href="/#faq"
        className="any_queries"
        style={{
            textDecoration: "none",
            color: "white",
            marginTop: "5px",
            marginBottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: "10px",
          }}
      >Any queries?</a>
      </section>
      <Footer/>
    </div>
  );
}

export default Aha;
