import React from 'react';
import { Container, Row } from 'react-bootstrap';
import aboutHero from '../assets/img/Person-on-sofa.webp';

function Aboutus() {
  return (
    <div>
        <section className='about' id="aboutus">
            <Container>
                <Row className=""
                    style={{
                        marginTop: '30px',
                    }} >
                    <div className="col-md-6">
                        <p className='fs-3' style={{position:"relative",top:"30%"}}>
                          “One stop place for <span className='fs-2 fw-bold' style={{color:"#fed530"}}>Instant</span> <br/>
                          entertainment stream“.<br/>
                          Experience the zone of <span className='fs-2 fw-bold' style={{color:"#fed530"}}>premium</span> with<br/>
                          <span className='fs-3 fw-bold' style={{color:"#fed530"}}>weiw [View].</span>
                        </p>                        
                    </div>
                    <div className="col-md-6">
                        <img src={aboutHero} alt="aboutus" className="img-fluid about_img"/>
                    </div>
                </Row>
            </Container>
        </section>  
    </div>
  )
}

export default Aboutus
