import React, { Component } from "react";
import { NavBar } from "./NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MRow from "./MRow";
import "./PageBanner.css";
import PageBannerComp from "./PageBannerComp";
import { ProgressB } from "./ProgressB";
import Footer from "./Footer";
import voot from "../assets/img/vootselect.webp";
import adhm from "../assets/img/banners/adhm.jpg";
import sadhm from "../assets/img/banners/sadhm.jpg"
import kgf from "../assets/img/banners/kgf.jpg"
import skgf from "../assets/img/banners/skgf.jpg"
import zom from "../assets/img/banners/zom.jpg"
import szom from "../assets/img/banners/szom.jpg"
function Voot() {
  // generate random number between 1 and 4
  const seq = Math.floor(Math.random() * 3) + 1;

  return (
    <div>
      <NavBar />
      <section className="pbanner" id="home">
        {seq === 1 && (
          <PageBannerComp
          image={
            window.innerWidth > 991 ? adhm : sadhm
          }
            title="Ae Dil Hai Mushkil"
            description="This story explores love-the shapes it takes, the ways it changes us and the exhilarating and often terrifying ride it takes us on."
            BannerCompLink="#buy"
            logoLink={voot}
            style={{
              height: "10vh",
            }}
          />
        )}
        {seq === 2 && (
          <PageBannerComp
          image={
            window.innerWidth > 800 ? kgf : skgf
          }
            title="KGF Chapter-1"
            description="In the 1970s, a gangster goes undercover as a slave to assassinate the owner of a notorious gold mine."
            BannerCompLink="#buy"
            logoLink={voot}
          />
        )}
        {seq === 3 && (
          <PageBannerComp
          image={
            window.innerWidth > 700 ? zom : szom
          }
            title="Zombie Reddy"
            description="Mario, a game developer, and his friends must tackle and escape from a rising zombie apocalypse in the region of Rayalaseema."
            BannerCompLink="#buy"
            logoLink={voot}
          />
        )}
      </section>
      <div
        className=""
        style={{
          marginTop: "20px",
          marginLeft: "20px",
        }}
      >
        <MRow
          title="New Releases"
          movies={[
            {
              id: 1,
              imglink:
              "https://m.media-amazon.com/images/M/MV5BZThkNGQ3OTItMjk1MS00MTRiLWFkYTMtNDQ5YmU3NWYyNDk2XkEyXkFqcGdeQXVyMTA5NzIyMDY5._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 2,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOTkxOTE2ZWQtY2JhZS00MWQ1LTg1NzUtOWFlMGJlZWE3ZjFlXkEyXkFqcGdeQXVyODAzNzAwOTU@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 3,
              imglink:
              "https://m.media-amazon.com/images/M/MV5BYjZhNTJmZjAtOGM0MC00NTM0LWJjZTYtMjBhMGUwZDdmYjBjXkEyXkFqcGdeQXVyODAzNzAwOTU@._V1_.jpg"
                
            },
            {
              id: 4,
              
              imglink:"https://m.media-amazon.com/images/M/MV5BODE5YzA3MmEtYWQ2Yy00OGNhLWE4MmQtY2I2NGZkNjZkYjVlXkEyXkFqcGdeQXVyMTE0MzY0NjE1._V1_.jpg",
            },
            {
              id: 5,
              imglink: "https://m.media-amazon.com/images/M/MV5BZjBlNWE5MWItNzFjYi00ZGJmLWIyNTYtNmExNWYwMjI5MmE4XkEyXkFqcGdeQXVyOTA3MTMyOTk@._V1_.jpg",
            },
            {
              id: 6,
              imglink:"https://m.media-amazon.com/images/M/MV5BNTk2MjUxMjEtZDU5OC00MzYxLTkwMWYtMGM5YWQ0MWMyN2I1XkEyXkFqcGdeQXVyMTE0MzY0NjE1._V1_FMjpg_UX1000_.jpg",           
            },
            {
              id: 7,
              imglink:"https://m.media-amazon.com/images/M/MV5BNWYzN2Q2YzgtODIwNS00ODcwLThkODItYWVhNmJkMDEzZmVlXkEyXkFqcGdeQXVyMTE0MzY0NjE1._V1_.jpg",
            },
            {
              id: 8,
              imglink:"https://m.media-amazon.com/images/M/MV5BMzc3ODNkZDEtYzRhYy00Yzg1LTk4ZmItMTI3YTk3ZDQyODdmXkEyXkFqcGdeQXVyMTA1NzEzOTU1._V1_.jpg",
            },
            {
              id: 9,
              imglink:"https://m.media-amazon.com/images/M/MV5BMWQ2ZGVkM2QtMzFmNi00ZmQwLThiZWQtN2Y3YTQ2NWYwZDUzXkEyXkFqcGdeQXVyOTk1MTQ5ODU@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 10,
              imglink:"",
            },
            {
              id: 11,
              imglink:"",
            },
            {
              id: 12,
              imglink:""
            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Movies"
          movies={[
            {
              id: 1,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BODE5YzA3MmEtYWQ2Yy00OGNhLWE4MmQtY2I2NGZkNjZkYjVlXkEyXkFqcGdeQXVyMTE0MzY0NjE1._V1_.jpg",
            },
            {
              id: 2,
              imglink:
                "https://upload.wikimedia.org/wikipedia/en/thumb/0/00/A1_Express.jpg/220px-A1_Express.jpg",
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZDNlNzBjMGUtYTA0Yy00OTI2LWJmZjMtODliYmUyYTI0OGFmXkEyXkFqcGdeQXVyODIwMDI1NjM@._V1_FMjpg_UX1000_.jpg ",
            },
            {
              id: 4,
              imglink:" https://m.media-amazon.com/images/M/MV5BMjY1NTI3MTQ2OF5BMl5BanBnXkFtZTgwNzg2NTc4OTE@._V1_.jpg",
            },
            {
              id: 5,
              imglink:"https://m.media-amazon.com/images/M/MV5BMjE4NDk4MTIwNl5BMl5BanBnXkFtZTgwNDEwODI5NzE@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 6,
              imglink:"https://m.media-amazon.com/images/M/MV5BMTc4ODA2MjQyMF5BMl5BanBnXkFtZTgwMTY3MDI1NzM@._V1_FMjpg_UX1000_.jpg",           
            },
            {
              id: 7,
              imglink:"https://m.media-amazon.com/images/M/MV5BMTY5MTIwMDg0N15BMl5BanBnXkFtZTgwNjA3OTcwMzE@._V1_.jpg",
            },
            {
              id: 8,
              imglink:"https://m.media-amazon.com/images/M/MV5BMjU4MDIyNjctMjRlNy00YmI0LThmNDAtNzhlZjgwMWJhOThiXkEyXkFqcGdeQXVyMzU0ODc1MTQ@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 9,
              imglink:"https://assets-in.bmscdn.com/iedb/movies/images/mobile/thumbnail/xlarge/badava-rascal-et00313076-21-12-2021-03-16-51.jpg",
            },
            {
              id: 10,
              imglink:"https://m.media-amazon.com/images/M/MV5BYzM1M2UzYmQtOTM4MS00MzI2LTk1ZjktYTc5ZGU5MzBiNDQzXkEyXkFqcGdeQXVyMTE0MzY0NjE1._V1_.jpg",
            },
            {
              id: 11,
              imglink:"https://m.media-amazon.com/images/M/MV5BNjkwMmU3MGMtYmUyNi00NTQzLThmZTUtOTc2Nzg3NjNmNWNkXkEyXkFqcGdeQXVyMTA4NjE0NjEy._V1_.jpg",
            },
            {
              id: 12,
              imglink:" https://m.media-amazon.com/images/M/MV5BYTE0YjFmZTYtYzNjMC00MjllLThmYzAtMTNhZDNhOGQ5ZTBmXkEyXkFqcGdeQXVyNzEwNjg3MjE@._V1_.jpg"
            },
          ]}
          isLargeRow={true}
        />
        <MRow
          title="Popular Shows"
          movies={[
            {
              id: 1,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BYTExNGVjZDQtNTZhZi00NGRiLThlOWItMjNlZjhiMzRhMmIyXkEyXkFqcGdeQXVyMTEwMTQ4MzU5._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 2,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BZjBlNWE5MWItNzFjYi00ZGJmLWIyNTYtNmExNWYwMjI5MmE4XkEyXkFqcGdeQXVyOTA3MTMyOTk@._V1_.jpg",
            },
            {
              id: 3,
              imglink:
                "https://m.media-amazon.com/images/M/MV5BOTkxOTE2ZWQtY2JhZS00MWQ1LTg1NzUtOWFlMGJlZWE3ZjFlXkEyXkFqcGdeQXVyODAzNzAwOTU@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 4,
              imglink:"https://m.media-amazon.com/images/M/MV5BYWNlYmZkZjQtNjU5OS00YTNkLWJmOTEtYmZiMmUwZGI3NTM3XkEyXkFqcGdeQXVyMTM2NTIwMDIw._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 5,
              imglink:"https://m.media-amazon.com/images/M/MV5BNzc1YmQ4ZWMtYzI2Ni00ZDZkLWE5OTEtMmNlMzYxZGMyY2M3XkEyXkFqcGdeQXVyMTE0MzY0NjE1._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 6,
              imglink:"https://m.media-amazon.com/images/M/MV5BYmY5YmJiM2QtNjdhOC00NjRhLTgyNDEtYmM1NmJhNjc5NDE2XkEyXkFqcGdeQXVyMjQ4ODcxNTM@._V1_FMjpg_UX1000_.jpg",           
            },
            {
              id: 7,
              imglink:"https://m.media-amazon.com/images/M/MV5BYWI5NzQ0ZGUtZTU4Zi00OTE5LWI2OWUtM2FkMGE0MjIzYTk4XkEyXkFqcGdeQXVyODAzNzAwOTU@._V1_.jpg",
            },
            {
              id: 8,
              imglink:"https://m.media-amazon.com/images/M/MV5BZDE2OWEyOTItMmQ1Mi00OGNlLWFmNjEtZDU3OGE2OTk1YjY5XkEyXkFqcGdeQXVyOTA3MTMyOTk@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 9,
              imglink:"https://m.media-amazon.com/images/M/MV5BMzU1YWU3ZWItYTA4Yy00M2Q0LTkzZTQtYzFmNzVlZTg1ZTQwXkEyXkFqcGdeQXVyMTM1MTE1NDMx._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 10,
              imglink:"https://m.media-amazon.com/images/M/MV5BNGJmMjExYTItZmJmMi00OWY4LWFjYTktMjYzYmRlOGJjZmJjXkEyXkFqcGdeQXVyMTUyMTU1MDg1._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 11,
              imglink:"https://m.media-amazon.com/images/M/MV5BOTU3ZGJkNTEtZTc3Mi00MDk0LThjMGUtMmVjZjIxMTFhNTNjXkEyXkFqcGdeQXVyMTAyMTkwNzg@._V1_FMjpg_UX1000_.jpg",
            },
            {
              id: 12,
              imglink:"https://m.media-amazon.com/images/M/MV5BNWM4YjcxYmQtYzVhMi00NTVlLWJiZTQtMTQwNGE1ZTcyN2U2XkEyXkFqcGdeQXVyMTY2Nzk0NDY@._V1_FMjpg_UX1000_.jpg"
            },
          ]}
          isLargeRow={true}
        />
      </div>
      <div
        classsName="col-md-11"
        style={{
          marginTop: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ProgressB
            ott="Voot"
            beginnerPrice="3"
            cinephilePrice="5"
            bingePrice="7"
        />        
      </div>
      <section id="buy">
      <a
        href="/#faq"
        className="any_queries"
        style={{
            textDecoration: "none",
            color: "white",
            marginTop: "5px",
            marginBottom: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: "10px",
          }}
      >Any queries?</a>
      </section>
      <Footer/>
    </div>
  );
}

export default Voot;
